<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchForm" ref="searchForm" class="demo-form-inline">
            <el-form-item label="关键字" prop="keyword" class="mr-20">
                <el-input v-model="searchForm.keyword" placeholder="搜索关键字"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="dialogFormVisible = true">添加保险公司</el-button>
            </el-form-item>
        </el-form>

        <el-table
                :data="tableData"
                border
                class="mt-10"
                style="width: 100%">
            <el-table-column
                    prop="name"
                    label="保险公司名称">
            </el-table-column>
            <el-table-column
                    prop="license"
                    label="提成比例">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="添加时间">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="操作"
            >
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="dialogFormVisible = true">编辑</el-button>
                    <el-button type="text" size="small" @click="onDelete">删除</el-button>
                </template>
            </el-table-column>


        </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchForm.page"
                :page-size="searchForm.size"
                layout="total, prev, pager, next"
                :total="searchForm.count">
        </el-pagination>

        <el-dialog title="添加保险公司" :visible.sync="dialogFormVisible">
            <el-form class="follow-form" ref="form" :model="form" label-width="110px">

                <el-form-item label="保险公司" required="">
                    <el-input v-model="form.name" placeholder="请输入保险公司名称"></el-input>
                </el-form-item>
                <el-form-item label="提成比例">
                    <div>
                        <el-input v-model="form.number" placeholder="请输入1-100数字" style="width: 80%;margin-right: 5px"></el-input>%</div>
                    <div class="c-999">合同金额百分比</div>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer txt-center">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                dialogFormVisible:false,
                searchForm:{
                    keyword:'',
                },
                tableData:[{name:'xx',license:'2020-04-20'}],
                form:{
                    name:'',
                    number:''
                }
            }
        },
        methods: {
            // 点击分页
            handleSizeChange(val) {
                this.searchForm.size = val;
                this.init();
            },

            handleCurrentChange(val) {
                console.log(val);
                this.searchForm.page = val;
                this.init();
            },
            onSubmit(){
                this.dialogFormVisible = false
            },
            onDelete(){
                this.$confirm('确定删除该保险公司吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '已删除!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        }
    }
</script>

<style scoped>
</style>